import React from 'react';

import Game from './components/Game.js';

function App() {
  return (
    <div className="App">
    <Game />
    </div>
  );
}

export default App;
